/* montserrat thin 100 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 100;
  src: url('../../assets/fonts/montserrat-v12-latin-100.eot');
  /* IE9 Compat Modes */
  src: local('Montserrat Thin'), local('Montserrat-Thin'),
    url('../../assets/fonts/montserrat-v12-latin-100.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('../../assets/fonts/montserrat-v12-latin-100.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('../../assets/fonts/montserrat-v12-latin-100.woff') format('woff'),
    /* Modern Browsers */
    url('../../assets/fonts/montserrat-v12-latin-100.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('../../assets/fonts/montserrat-v12-latin-100.svg#Montserrat') format('svg');
  /* Legacy iOS */
}

/* montserrat extra-light 200 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 200;
  src: url('../../assets/fonts/montserrat-v12-latin-200.eot');
  /* IE9 Compat Modes */
  src: local('Montserrat ExtraLight'), local('Montserrat-ExtraLight'),
    url('../../assets/fonts/montserrat-v12-latin-200.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('../../assets/fonts/montserrat-v12-latin-200.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('../../assets/fonts/montserrat-v12-latin-200.woff') format('woff'),
    /* Modern Browsers */
    url('../../assets/fonts/montserrat-v12-latin-200.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('../../assets/fonts/montserrat-v12-latin-200.svg#Montserrat') format('svg');
  /* Legacy iOS */
}

/* montserrat light 300 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  src: url('../../assets/fonts/montserrat-v12-latin-300.eot');
  /* IE9 Compat Modes */
  src: local('Montserrat Light'), local('Montserrat-Light'),
    url('../../assets/fonts/montserrat-v12-latin-300.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('../../assets/fonts/montserrat-v12-latin-300.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('../../assets/fonts/montserrat-v12-latin-300.woff') format('woff'),
    /* Modern Browsers */
    url('../../assets/fonts/montserrat-v12-latin-300.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('../../assets/fonts/montserrat-v12-latin-300.svg#Montserrat') format('svg');
  /* Legacy iOS */
}

/* montserrat regular 400 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: url('../../assets/fonts/montserrat-v12-latin-regular.eot');
  /* IE9 Compat Modes */
  src: local('Montserrat Regular'), local('Montserrat-Regular'),
    url('../../assets/fonts/montserrat-v12-latin-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('../../assets/fonts/montserrat-v12-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('../../assets/fonts/montserrat-v12-latin-regular.woff') format('woff'),
    /* Modern Browsers */
    url('../../assets/fonts/montserrat-v12-latin-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('../../assets/fonts/montserrat-v12-latin-regular.svg#Montserrat') format('svg');
  /* Legacy iOS */
}

/* montserrat bold 700 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: url('../../assets/fonts/montserrat-v12-latin-700.eot');
  /* IE9 Compat Modes */
  src: local('Montserrat Bold'), local('Montserrat-Bold'),
    url('../../assets/fonts/montserrat-v12-latin-700.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('../../assets/fonts/montserrat-v12-latin-700.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('../../assets/fonts/montserrat-v12-latin-700.woff') format('woff'),
    /* Modern Browsers */
    url('../../assets/fonts/montserrat-v12-latin-700.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('../../assets/fonts/montserrat-v12-latin-700.svg#Montserrat') format('svg');
  /* Legacy iOS */
}

/* montserrat black 900 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 900;
  src: url('../../assets/fonts/montserrat-v12-latin-900.eot');
  /* IE9 Compat Modes */
  src: local('Montserrat Black'), local('Montserrat-Black'),
    url('../../assets/fonts/montserrat-v12-latin-900.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('../../assets/fonts/montserrat-v12-latin-900.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('../../assets/fonts/montserrat-v12-latin-900.woff') format('woff'),
    /* Modern Browsers */
    url('../../assets/fonts/montserrat-v12-latin-900.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('../../assets/fonts/montserrat-v12-latin-900.svg#Montserrat') format('svg');
  /* Legacy iOS */
}

.font-family(@size, @weight: 900, @style: normal, @stretch: normal) {
  font-family: Montserrat;
  font-size: @size;
  font-weight: @weight;
  font-style: @style;
  font-stretch: @stretch;
}

h1 {
  font-family: Montserrat;
  font-size: 72px;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.17;
  letter-spacing: normal;
}

h2 {
  font-family: Montserrat;
  font-size: 36px;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
}

h3 {
  font-family: Montserrat;
  font-size: 28px;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: normal;
}

h4 {
  font-family: Montserrat;
  font-size: 22px;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.36;
  letter-spacing: normal;
}

h5 {
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
}

h6 {
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-stretch: normal;
  line-height: 2;
  letter-spacing: normal;
}

p {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  margin-top: 0;
  margin-bottom: 1em;
}

.display-one {
  font-family: Montserrat;
  font-size: 60px;
  font-weight: 200;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.2;
  letter-spacing: -1.5px;
}

.display-two {
  font-family: Montserrat;
  font-size: 36px;
  font-weight: 200;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
}

.display-three {
  font-family: Montserrat;
  font-size: 48px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: -1px;
}

.lead {
  font-family: Montserrat;
  font-size: 40px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: -1.2px;
}

.info {
  font-family: Montserrat;
  font-size: 13px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.85;
  letter-spacing: normal;
}