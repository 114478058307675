:root {
  --primary-11: #02090b;
  --primary-10: #011814;
  --primary-9: #012a1d;
  --primary-8: #00412a;
  --primary-7: #004d32;
  --primary-6: #005f3c;
  --primary-5: #29795b;
  --primary-4: #57967f;
  --primary-3: #82b19f;
  --primary-2: #b2cfc4;
  --primary-1: #e0ebe7;

  --primary-color: var(--primary-6); // color used in main actions

  --text-color: black;
  --input-focus-shadow-color: #012a1d; // outer shadow of input on focus - GIVE 20% ALPHA

  --background-color: #f3f3f3;

  --menu-text-color: black;
  --menu-item-active-bg: #b2cfc4; // color of the side menu active ite
  --menu-bg: #f4f9fa; // color of the background of the menu
  --menu-sub-bg: #ffffff; // color of the background of the submenu

  --login-background-color: var(--primary-10);

  --header-logo-background: var(--primary-9);
  --header-background: var(--primary-color);
  --header-text-color: #fff;

  --footer-text-color: #fff;
  --footer-color: var(--primary-5);
}

@primary-11: #02090b;
@primary-10: #011814;
@primary-9: #012a1d;
@primary-8: #00412a;
@primary-7: #004d32;
@primary-6: #005f3c;
@primary-5: #29795b;
@primary-4: #57967f;
@primary-3: #82b19f;
@primary-2: #b2cfc4;
@primary-1: #e0ebe7;

@primary-color: #005f3c;
@component-background: #ffffff;
@background-color-base: #ffffff;